import React from 'react';
import bgImage from '../images/bg_pix.jpg'; 
const Home = () => {
  return (
    <div className="image-container">
      <img src={bgImage} alt="Background Image" className="bg-img" />
      <h1 className="centered-text">DISRUPT ARTISTS</h1>
    </div>
  );
};

export default Home;
