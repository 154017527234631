import React from 'react';
import MediaImage from '../images/media-image.jpg';
import PackageImage from '../images/basic.jpg';
import Intermediate from '../images/intermediate.png';

const Media = () => {
  return (
    <div>
      <section className="hero-section text-light ">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <img src={MediaImage} className="img-fluid p-4 custom-img" style={{ maxWidth: '90%', marginLeft:'50px' }} alt="Social Media Icons" />
            </div>
            <div className="col-md-6 media-text">
              <h3>Disrupt Media</h3>
              <p>Manage your online presence with us.</p>
              <a href="#"><button className="btn btn-light">Read more</button></a>
            </div>
          </div>
        </div>
      </section>

      <section className="package-section text-light py-5">
      <div className="container">
  <div className="row align-items-center">
    <div className="col-md-6">
      <div className="package-text p-4" style={{marginLeft:'16%', marginTop:'-10%'}}>
        <h3>Basic Package £9.99pw</h3>
        <p>Ensures your followers are up to date with your music on all the platforms that matter.</p>
        <hr />
        <p>Includes:</p>
        <ul className="list-unstyled">
          <li>– 1 video per week</li>
          <li>– 1 image per week</li>
          <li>– Spotify playlist pitches</li>
          <li>– Beatport charts</li>
        </ul>
        <hr />
        <a href="#"><button className="btn btn-light">Sign up</button></a>
      </div>
    </div>
    <div className="col-md-6">
      <img src={PackageImage} className=" img-fluid package-img" alt="DJ at work" />
    </div>
  </div>
</div>

      </section>
      <section className="package-section Intermediate text-light pb-3">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <img src={Intermediate} className="img-fluid p-3 custom-img" style={{ maxWidth: '90%', marginLeft:'50px' }} alt="Club scene" />
            </div>
            <div className="col-md-6 intermediate-text">
              <h3>Intermediate Package<br />£24.99pw</h3>
              <a href=""><button className="btn btn-light mt-3">Coming soon</button></a>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Media;
